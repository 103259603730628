import Vue from "vue";

let EventHub = null;
let EventHub2 = null;
let EventHub3 = null;
let EventHub4 = null;

let commLayerMixin = {
  methods: {
    _showLayer(param, func, func2) {
      if (func) {
        if (param.order === 2) {
          if (param.type === "alert") {
            EventHub2 = new Vue();
            EventHub2.$on("alert-ok-2", func);
          }
          if (param.type === "confirm") {
            EventHub2 = new Vue();
            EventHub2.$on("confirm-ok-2", func);
            if (func2) {
              EventHub4 = new Vue();
              EventHub4.$on("confirm-cancel2", func2);
            }
          }
          if (param.type === "popup") {
            EventHub2 = new Vue();
            EventHub2.$on("popup-ok-2", func);
          }
        } else {
          if (param.type === "alert") {
            EventHub = new Vue();
            EventHub.$on("alert-ok", func);
          }
          if (param.type === "confirm") {
            EventHub = new Vue();
            EventHub.$on("confirm-ok", func);
            if (func2) {
              EventHub3 = new Vue();
              EventHub3.$on("confirm-cancel", func2);
            }
          }
          if (param.type === "popup") {
            EventHub = new Vue();
            EventHub.$on("popup-ok", func);
          }
        }
      }
      if (param.order === 2) {
        this.$store.commit("CommStore/showLayer2", param);
      } else {
        this.$store.commit("CommStore/showLayer", param);
      }
    },
    _closeLayer(order) {
      if (order === 2) {
        this.$store.commit("CommStore/closeLayer2");
      } else {
        this.$store.commit("CommStore/closeLayer");
      }
    },
    _okAlert(layer) {
      let order = layer.order;
      if (order === 2) {
        if (EventHub2 !== null) {
          EventHub2.$emit("alert-ok-2");
        }
      } else {
        if (EventHub !== null) {
          EventHub.$emit("alert-ok");
        }
      }
      if (layer.type === "alert") {
        this._closeLayer(order);
      }
    },
    _okConfirm(layer) {
      let order = layer.order;
      if (order === 2) {
        if (EventHub2 !== null) {
          EventHub2.$emit("confirm-ok-2");
        }
      } else {
        if (EventHub !== null) {
          EventHub.$emit("confirm-ok");
        }
      }
      if (layer.type === "confirm") {
        this._closeLayer(order);
      }
    },
    _cancelConfirm(layer) {
      let order = layer.order;
      if (order === 2) {
        if (EventHub4 !== null) {
          EventHub4.$emit("confirm-cancel-2");
        }
      } else {
        if (EventHub3 !== null) {
          EventHub3.$emit("confirm-cancel");
        }
      }
      if (layer.type === "confirm") {
        this._closeLayer(order);
      }
    },
    _okPopup(data, order) {
      if (order === 2) {
        if (EventHub2 !== null) {
          EventHub2.$emit("popup-ok-2", data);
        }
        this._closeLayer(order);
      } else {
        if (EventHub !== null) {
          EventHub.$emit("popup-ok", data);
        }
        this._closeLayer(order);
      }
    },
  },
};

export default commLayerMixin;

<template>
  <div id="mArticle">
    <div class="head_section">
      <h2 id="dkBody" class="tit_section">가이드 관리</h2>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <!-- admin 타입 type_admin -->
        <caption class="ir_caption">
          일반/법인카드 계정 관리 표
        </caption>
        <colgroup>
          <col style="width: 160px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">제목 <sup>*</sup></th>
            <td>
              <Input :value.sync="subject" :maxLength="100" />
            </td>
          </tr>
          <tr>
            <th scope="row">내용 <sup>*</sup></th>
            <td style="height: 400px">
              <textarea id="summernote" name="editordata" />
              <!-- <CommKeditor :height="'100%'" :content.sync="contents"/> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="wrap_btn type_bottom">
      <div class="pos_left">
        <button type="button" class="btn_large btn_fourthly" @click.prevent="goToList()">
          취소
        </button>
      </div>
      <div class="pos_right">
        <button type="button" class="btn_large btn_primary" @click.prevent="checkValidation">
          저장
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import CommKeditor from '@/_approval/components/common/CommKeditor';
import Input from "@/_approval/components/common/input/Input";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";
import { decodeHTMLEntities } from "@/utils/stringUtils";

export default {
  name: "UseGuideEdit",
  components: {
    // CommKeditor,
    Input,
  },
  mixins: [CommLayerMixin],
  data() {
    return {
      subject: "",
      contents: "",
      guideFlag: "",
      guideId: "",
    };
  },
  beforeDestroy() {
    const summernote = $("#summernote");
    if (summernote) summernote.summernote("destroy");
  },
  mounted() {
    this.createSummernote({
      height: 350,
    });
  },
  created() {
    this.guideId = this.$route.query.guideId;
    this.getGuide();
  },
  methods: {
    async getGuide() {
      const path = `${this.$apiPath.APRVL_GUIDE}/` + this.guideId;
      const result = await ApiService.shared.getData(path);
      if (!result.data) return;

      const note = result.data.contents;
      const noteDecoded = decodeHTMLEntities(document, note);
      $("#summernote").summernote("code", noteDecoded);

      this.subject = result.data.title;
      // this.contents = result.data.contents;
      this.regDate = result.data.regDate;
    },

    async updateUseGuide() {
      // 가이드 수정

      const path = `${this.$apiPath.APRVL_GUIDE}`;
      const obj = this.getObjToSend();
      const result = await ApiService.shared.putData(path, obj);

      if (result.code !== "200") {
        this.$emit("alert", result.text);
        return;
      }

      this.goToList();
    },
    getObjToSend() {
      let obj = {
        guideNo: this.guideId,
        title: this.subject,
        contents: $("#summernote").summernote("code"),
      };

      return obj;
    },
    checkValidation() {
      // 유효성 체크
      var contents = $("#summernote").summernote("code");

      if (this.subject === "" || contents === "") {
        const layer = {};
        layer.type = "alert";
        layer.order = 2;
        layer.content = "입력된 내용이 없습니다"; // 레이어 내용
        this._showLayer(layer);
        return false;
      } else {
        this.confirmSaveUseGuide();
      }
    },
    confirmSaveUseGuide() {
      // 2중 레이어?
      const layer = {};
      layer.type = "confirm";
      layer.header = "가이드 관리"; // 레이어 타이틀
      layer.content = "저장 하시겠습니까?"; // 레이어 내용
      this._showLayer(layer, this.updateUseGuide);
    },
    goToList() {
      this.$router.push(this.$paymentRouterPath.PAYMENT_SYSTEM_USE_GUIDE_LIST);
    },
    createSummernote(options) {
      const summernote = $("#summernote");

      if (!summernote) return;

      const self = this;

      var objBasic = {
        maximumImageFileSize: 1048576, // 1메가.
        callbacks: {
          onImageUploadError: function (msg) {
            // upload image to server and create imgNode...
            self.alert(msg);
          },
        },
      };

      if (options) {
        Object.assign(objBasic, options);
      }

      summernote.summernote(objBasic);
    },
  },
};
</script>

<style scoped></style>

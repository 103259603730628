export function getCanConvertNumber(value) {
  if (value === 0) return true;

  if (!value) return false;

  return !isNaN(value);
}

export function padLeft(number, n, str = "0") {
  return Array(n - String(number).length + 1).join(str) + number;
}

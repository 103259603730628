<template>
  <InputBase :class="{ disabled: isDisabled, error: isError }">
    <input
      ref="input"
      v-model="input"
      :type="type"
      :placeholder="placeholder"
      :maxLength="maxLength"
      :disabled="isDisabled"
      @blur="onBlur"
      @input="onInput"
      @focusout="(e) => $emit('focusout', e)"
      @focus="(e) => $emit('focus', e)"
      @keyup="(e) => $emit('keyup', e)"
      @keyup.13="onKeyUpEnter"
    />
    <!-- @focus="onFocus" -->
  </InputBase>
</template>

<script>
import InputBase from "@/_approval/components/common/input/InputBase";
import { getCanConvertNumber } from "@/utils/numberUtils";

export default {
  name: "Input",
  components: {
    InputBase,
  },
  props: {
    value: String,
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    maxLength: Number,
    isFocusOnMount: {
      type: Boolean,
      default: false,
    },
    allowString: Boolean,
  },
  computed: {
    input: {
      get() {
        if (!this.value) return;

        if (this.maxLength && this.value.length > this.maxLength) {
          this.$emit("update:value", this.value.slice(0, this.maxLength));
        }
        return this.value;
      },
      set(value) {
        // if(this.allowString == false){
        //   const validText = this.getValidText( value );
        //   if( validText !== value ) {
        //     this.$emit("update:value", validText );
        //     return;
        //   }
        // }

        this.$emit("update:value", value);
      },
    },
  },
  mounted() {
    if (this.isFocusOnMount) {
      this.$refs.input.focus();
    }
  },
  methods: {
    blur() {
      // 외부에서 호출 될 메서드.
      this.$refs.input.blur();
    },
    onFocus(e) {
      this.$emit("update:focus", e);
    },
    onBlur(e) {
      this.$emit("blur", e);
    },
    onKeyUpEnter(e) {
      this.blur();

      this.$emit("onEnterKey", e);
    },
    onInput(e) {
      this.$emit("onInput", e);
    },
    getValidText(strOrg) {
      if (!strOrg) return "";

      strOrg = strOrg.trim();

      // 그냥 다 숫자면 그대로 리턴.
      if (getCanConvertNumber(strOrg)) {
        return strOrg;
      }

      let ret = "";

      let char;

      for (var i = 0; i < strOrg.length; ++i) {
        char = strOrg.charAt(i);

        if (!getCanConvertNumber(char)) continue;

        ret += char;
      }

      return ret;
    },
  },
};
</script>
